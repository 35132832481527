<template>
  <v-app>
    <nav-component :drawer="true" title="Reports">
      <div class="flexRow" style="margin-top: 20px;">
        <v-select label="Campaign" style="width: 150px; margin-right: 5px;" v-model="campaign"
                  :items="[{text: 'All (Time)', value: null}, {text: 'All (Time / Campaign)', value: 'ALL_GB_TIMECAMPAIGN'}].concat(Object.keys(campaigns).map(r => {return {text: campaigns[r].name, value: campaigns[r].id}}))"/>
        <v-select label="Time"
                  :items="[{text: 'Monthly', value: 'MONTHLY'}, {text: 'Weekly', value: 'WEEKLY'}, {text: 'Daily', value: null}, {text: 'Hourly', value: 'HOURLY'}]"
                  style="width: 100px; margin-right: 5px;"
                  v-model="time"/>
        <v-select :label="durationRange ? durationRange[0] + ' - ' + durationRange[1] : 'Duration'"
                  :items="[{text: 'Last month', value: null}, {text: '30 days', value: 'DAYS30'}, {text: '7 days', value: 'DAYS7'}, {text: 'Today', value: 'TODAY'}, {text: 'Range', value: 'RANGE'}]"
                  style="width: 140px; margin-right: 5px;"
                  v-model="duration" @change="ondurationchange()"/>
        <v-btn color="secondary" v-text="'GO'" @click="go"/>
      </div>
    </nav-component>
    <v-main>
      <v-dialog v-model="durationRangePopup" width="400">
        <v-date-picker color="secondary" v-model="durationRange" range @change="durationRangePopup = false"/>
      </v-dialog>
      <v-dialog v-model="detailsPopup" width="600">
        <v-card>
          <v-card-title v-text="'Details'" />
          <v-card-text>
            <v-data-table v-if="results"
                          :items-per-page="50"
                          :headers="[
                      { text: 'Date', value: 'id.date' },
                      { text: 'Type', value: 'eventType' },
                    ]"
                          :items="results.details">
              <template v-slot:item.id.date="{ item }">
                <span v-text="$date(new Date(item.id.date), 'yyyy-MM-dd HH:mm')"/>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-data-table v-if="results"
                    :items-per-page="50"
                    :headers="headers()"
                    :items="results.map(result => {
                      return {...result,
                      campaign: !result.campaignId || result.campaignId.length === 0 ? '-' : campaigns.find(c => c.id === result.campaignId).name}
                    })">
        <template v-slot:item.date="{ item }">
          <span style="cursor: pointer;" v-text="$date(new Date(item.date), format)" @click="details(new Date(item.date))"/>
        </template>
        <template v-slot:item.clickRate="{ item }">
          {{ typeof item.clickRate === 'string' ? '-' : ((parseFloat(item.clickRate) * 100).toFixed(2) + '%') }}
        </template>
        <template v-slot:item.installRate="{ item }">
          {{ typeof item.installRate === 'string' ? '-' : ((parseFloat(item.installRate) * 100).toFixed(2) + '%') }}
        </template>
        <template v-slot:item.uninstallRate="{ item }">
          {{ typeof item.uninstallRate === 'string' ? '-' : ((parseFloat(item.uninstallRate) * 100).toFixed(2) + '%') }}
        </template>
      </v-data-table>
      <v-btn v-if="results" style="margin: 10px;" color="secondary" v-text="'EXPORT'" @click="csv"/>

    </v-main>
  </v-app>
</template>

<script>

import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";
import {saveAs} from 'file-saver';

export default {
  async mounted() {
    this.campaigns = await this.getCampaigns(false);
  },
  mixins: [utils],
  components: {
    NavComponent
  },
  data() {
    return {
      campaigns: {},
      campaign: null,
      time: null,
      duration: null,
      durationRangePopup: false,
      detailsPopup: false,
      durationRange: null,
      results: null,
      format: 'yyyy-MM-dd',
    };
  },
  methods: {
    ondurationchange() {
      if (this.duration === 'RANGE') {
        this.durationRangePopup = true;
      } else {
        this.durationRange = null;
      }
    },
    async go() {
      this.format = this.time === 'HOURLY' ? 'yyyy-MM-dd HH:00' : 'yyyy-MM-dd';
      const params = {};
      if (this.campaign) params.campaign = this.campaign;
      if (this.time) params.time = this.time;
      if (this.duration) params.duration = this.duration;
      if (this.durationRange) {
        params.rangeFrom = this.durationRange[0];
        params.rangeTo = this.durationRange[1];
      }
      if (this.campaign) params.campaign = this.campaign;
      this.results = (await this.httpGet('api/report/campaign?' + new URLSearchParams(params))).data;
    },
    async details(fromTime) {
      let toTime;
      switch (this.time) {
        case 'MONTHLY':
          toTime = new Date(fromTime.getTime());
          toTime.setTime(toTime.setMonth(toTime.getMonth() + 1));
          break;
        case 'WEEKLY':
          toTime = new Date(fromTime.getTime() + (7 * 24 * 60 * 60 * 1000));
          break;
        case 'HOURLY':
          toTime = new Date(fromTime.getTime() + (60 * 60 * 1000));
          break;
        case 'DAILY':
        default:
          toTime = new Date(fromTime.getTime() + (24 * 60 * 60 * 1000));
          break;
      }
      const params = {fromTime: fromTime.toISOString(), toTime: toTime.toISOString()};
      if (this.campaign) params.campaign = this.campaign;
      const res = await this.httpGet('api/report/campaign/details?' + new URLSearchParams(params));
      this.results.details = res.data;
      this.detailsPopup = true;
    },
    csv() {
      this.format = this.time === 'HOURLY' ? 'yyyy-MM-dd HH:00' : 'yyyy-MM-dd';
      let blobData = this.campaign === 'ALL_GB_TIMECAMPAIGN'
          ? '\'Date\', \'Campaign\', \'Registers\', \'Views\', \'Clicks\', \'Installs\', \'Uninstalls\', \'Messages\', \'Click rate\', \'Install rate\', \'Uninstall rate\'\r\n'
          : '\'Date\', \'Registers\', \'Views\', \'Clicks\', \'Installs\', \'Uninstalls\', \'Messages\', \'Click rate\', \'Install rate\', \'Uninstall rate\'\r\n';
      this.results.forEach(a => {
        blobData += '\'' + this.$date(new Date(a.date), this.format) + '\',' +
            (this.campaign === 'ALL_GB_TIMECAMPAIGN' ? !a.campaignId || a.campaignId.length === 0 ? '' : '\'' + this.campaigns.find(c => c.id === a.campaignId).name : '') + '\'' +
            a.totalRegisters + ',' +
            a.totalViews + ',' +
            a.totalClicks + ',' +
            a.totalInstalls + ',' +
            a.totalUninstalls + ',' +
            a.totalMessages + ',' +
            (typeof a.clickRate === 'string' ? '' : (parseFloat(a.clickRate) * 100).toFixed(2)) + ',' +
            (typeof a.installRate === 'string' ? '' : (parseFloat(a.installRate) * 100).toFixed(2)) + ',' +
            (typeof a.uninstallRate === 'string' ? '' : (parseFloat(a.uninstallRate) * 100).toFixed(2)) + '\r\n';
      })
      saveAs(new Blob([blobData], {type: "text/csv;charset=utf-8"}), 'data.csv');
    },
    headers() {
      return this.campaign === 'ALL_GB_TIMECAMPAIGN' ? [
        { text: 'Date', value: 'date' },
        { text: 'Campaign', value: 'campaign' },
        { text: 'Registers', value: 'totalRegisters' },
        { text: 'Views', value: 'totalViews' },
        { text: 'Clicks', value: 'totalClicks' },
        { text: 'Installs', value: 'totalInstalls' },
        { text: 'Uninstalls', value: 'totalUninstalls' },
        { text: 'Messages', value: 'totalMessages' },
        { text: 'Click rate', value: 'clickRate' },
        { text: 'Install rate', value: 'installRate' },
        { text: 'Uninstall rate', value: 'uninstallRate' },
      ] : [
        { text: 'Date', value: 'date' },
        { text: 'Registers', value: 'totalRegisters' },
        { text: 'Views', value: 'totalViews' },
        { text: 'Clicks', value: 'totalClicks' },
        { text: 'Installs', value: 'totalInstalls' },
        { text: 'Uninstalls', value: 'totalUninstalls' },
        { text: 'Messages', value: 'totalMessages' },
        { text: 'Click rate', value: 'clickRate' },
        { text: 'Install rate', value: 'installRate' },
        { text: 'Uninstall rate', value: 'uninstallRate' },
      ];
    }
  }
}
</script>

<style scoped>
.flexRow {
  display: flex;
  flex-direction: row;
}
</style>

<template>
  <v-app>
    <nav-component :drawer="false" title="Login" />
    <v-main>
      <div style="alignment: center;">
        <v-card style="max-width: 600px; margin: 150px auto auto auto; padding: 50px;">
          <h1>Login</h1>
          <v-text-field label="Email" type="email" v-model="user" />
          <v-text-field label="Password" type="password" v-model="password" :rules="[validationRules.password]" />
          <v-btn color="secondary" @click="login">Login</v-btn>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import jsSHA from 'jssha'
import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";

export default {
  mixins: [utils],
  data () {
    return {
      user: null,
      password: null,
      validationRules: {
        // eslint-disable-next-line no-useless-escape
        email: v => (!v || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v)) || 'Please enter a valid email',
        password: v => (!v || /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v)) || 'password must be longer than 8 character and must contain at least one number'
      }
    }
  },
  components: {
    NavComponent
  },
  methods: {
    async login () {
      const shaObj = new jsSHA("SHA-512", "TEXT", { encoding: "UTF8" });
      if (!this.password || this.password.length === 0) {
        alert('You have to enter the password')
        return
      }
      shaObj.update(this.password);
      const result = await this.httpGet('api/auth', {'Authorization': 'Basic ' + btoa(this.user + ':' + shaObj.getHash('HEX'))})
      if (!result) {
        alert('Wrong credentials')
        return
      }
      localStorage.user = JSON.stringify(result.data);
      this.$router.replace({path: '/'});
    }
  }
}
</script>

import HomeComponent from './pages/index.vue'
import LoginComponent from './pages/login.vue'
import OverviewComponent from './pages/overview/index.vue'
import CampaignsComponent from './pages/campaigns/index.vue'
import AffiliatesComponent from './pages/affiliates/index.vue'
import ReportsComponent from './pages/reports/index.vue'
import SettingsComponent from './pages/settings.vue'
import UsersComponent from './pages/users/index.vue'

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent,
    children: [
      {
        path: '',
        name: 'overview',
        component: OverviewComponent,
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        component: CampaignsComponent,
      },
      {
        path: 'affiliates',
        name: 'affiliates',
        component: AffiliatesComponent,
      },
      {
        path: 'reports',
        name: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'users',
        name: 'users',
        component: UsersComponent,
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent }
]

<template>
  <div>
    <alert-component ref="alert"/>
    <i class="material-icons materialIcon" @click="openDialog()"
       v-text="campaign === null ? 'add_circle_outline' : 'create'"/>
    <v-icon class="materialIcon">mdi-add-circle-outline</v-icon>
    <v-dialog v-model="dialog" width="70%">
      <v-card class="d-flex flex-column">
        <v-card-title v-text="campaign === null ? 'New Campaign' : 'Update Campaign'"/>
        <v-card-text class="overflow-y-auto">
          <v-card class="card">
            <v-row>
              <v-col>
                <v-select label="Type" :items="[
                        {text: 'Affiliate', value: 'AFFILIATE'},
                        {text: 'MNO', value: 'MNO'},
                        {text: 'Message', value: 'MESSAGE'}
                      ]"
                          v-model="state.campaignType"
                          :disabled="campaign !== null"/>
              </v-col>
              <v-col>
                <v-text-field label="Name" v-model="state.name"
                              :disabled="campaign !== null && state.campaignType === 'MNO'"/>
              </v-col>
              <v-col>
                <v-select label="Trigger Type" :items="[
                        {text: 'Auto', value: 'AUTO'},
                        {text: 'Screen Idle', value: 'SCREEN_IDLE'},
                        {text: 'Screen Unlock', value: 'SCREEN_UNLOCK'},
                      ]"
                          v-model="state.triggerType"/>
              </v-col>
            </v-row>
          </v-card>
          <v-card v-if="campaign" class="card flexRow">
            <span style="margin: auto 15px auto 0px;">Targeted</span>
            <v-switch style="margin-right: 15px;" v-model="state.hasTargets"/>
            <!--            <v-text-field v-if="state.hasTargets === true" v-model="state.limit" style="margin-right: 15px;"-->
            <!--                          label="Limit"-->
            <!--                          type="number"/>-->
            <!--            <v-file-input v-if="state.hasTargets === true" style="margin-right: 15px;"-->
            <!--                          label="White list users (import CSV)" @change="onSelectFile"/>-->
            <template v-if="state.hasTargets === true">
              <v-text-field v-model="newIccid" label="Check / Add / Remove Identifier"/>
              <i class="material-icons materialIcon" style="margin-top: 15px;" v-text="'check_circle_outline'"
                 @click="targetedCheck(campaign.id, newIccid)"/>
              <i class="material-icons materialIcon" style="margin-top: 15px;" v-text="'add_circle_outline'"
                 @click="targetedAction(campaign.id, 'UPDATE', [newIccid])"/>
              <i class="material-icons materialIcon" style="margin-top: 15px; margin-right: 30px"
                 v-text="'remove_circle_outline'"
                 @click="targetedAction(campaign.id, 'REMOVE', [newIccid])"/>
              <v-file-input label="Add / Remove batch (CSV file)" @change="file => targetedFile = file"/>
              <i class="material-icons materialIcon" style="margin-top: 15px;" v-text="'add_circle_outline'"
                 @click="targetedAction(campaign.id, 'UPDATE_BATCH')"/>
              <i class="material-icons materialIcon" style="margin-top: 15px;" v-text="'remove_circle_outline'"
                 @click="targetedAction(campaign.id, 'REMOVE_BATCH')"/>
              <i class="material-icons materialIcon" style="margin-top: 15px; margin-left: 30px;" v-text="'delete_outline'"
                 @click="$refs.alert.show('Clear Targeted', 'Are you sure you want to clear the targeted identifiers?',
                [{text: 'Yes', callback: ()=> {targetedAction(campaign.id, 'REMOVE', [])}}, {text: 'No'}])"/>
            </template>
          </v-card>
          <v-card class="card">
            <day-component v-for="(day, i) of weekDays" :key="i" :day="day" :timeframes="state.timeFrame"
                           class="flexRow"/>
          </v-card>
          <v-card class="card">
            <div class="flexRow">
              <v-select label="Message type" :items="[
                {text: 'Auto', value: 'AUTO'},
                {text: 'Native', value: 'NATIVE'},
                {text: 'SMS', value: 'SMS'}]"
                        v-model="state.messageType"
                        style="flex-grow: unset; flex-shrink: unset; margin-right: 15px;"/>
              <span style="margin: auto 15px auto 0px;" v-text="'Platforms:'"/>
              <v-checkbox v-model="state.iphone"/>
              <img :src="require('@/assets/images/ios.png')"
                   style="width: 20px; height: auto; margin: auto 15px auto 0px;"/>
              <v-checkbox v-model="state.android"/>
              <img :src="require('@/assets/images/android.png')"
                   style="width: 20px; height: auto; margin: auto 15px auto 0px;"/>
            </div>
            <div class="flexRow">
              <v-text-field v-if="!state.iphone && !state.android" v-model="state.unknownUrl"
                            style="margin-right: 15px;" label="URL" :disabled="isMno"/>
              <v-text-field v-if="state.iphone" v-model="state.iphoneUrl" style="margin-right: 15px;"
                            label="iPhone URL" :disabled="isMno"/>
              <v-text-field v-if="state.android" v-model="state.androidUrl" style="margin-right: 15px;"
                            label="Android URL" :disabled="isMno"/>
            </div>
          </v-card>
          <v-card v-if="state.campaignType === 'AFFILIATE'" class="card">
            <v-select label="Affiliate" :items="affiliates"/>
          </v-card>
          <v-card v-if="state.campaignType === 'MNO'" class="card">
            <v-select
                v-model="state.appId"
                label="Campaign ID"
                :items="sdks.map(d => {return {text: d.name, value: d.appId}})"/>
            <v-textarea v-model="state.registerText" rows="2" label="Register Text" counter="150"/>
            <v-textarea v-model="state.retryText" rows="2" label="Retry Text" counter="150"/>
            <v-textarea v-model="state.retryText2" rows="2" label="Retry Text (After link open)"
                        counter="150"/>
            <div class="flexRow">
              <v-text-field v-model="state.maxRetries" type="number" label="Max Retries"
                            style="margin-right: 15px;"/>
              <v-text-field v-model="state.retryInterval" type="number" label="Retry Interval (mins)"/>
            </div>
            <v-textarea v-model="state.unpairText" label="Unpair text" rows="2" counter="150"/>
            <div class="flexRow">
              <v-text-field v-model="state.maxUnpairs" type="number" label="Max Unpairs"
                            style="margin-right: 15px;"/>
              <v-text-field v-model="state.unpairInterval" type="number" label="Unpair Interval (mins)"/>
            </div>
          </v-card>
          <v-card v-if="state.campaignType === 'MESSAGE'" class="card">
            <div class="flexRow">
<!--              <v-textarea v-model="state.header" rows="2" label="Header"/>-->
<!--            </div>-->
<!--            <div class="flexRow">-->
              <v-textarea v-model="state.message" rows="2" label="Message"
                          @input="state.emptyMessage = !state.message || state.message.length === 0"/>
              <v-checkbox v-model="state.emptyMessage"/>
              <div>
                Campaign<br/>
                with no<br/>
                prompt<br/>
              </div>
            </div>
          </v-card>
        </v-card-text>
        <v-btn class="card" :disabled="updating" color="secondary" v-text="campaign === null ? 'ADD' : 'UPDATE'"
               @click="submit()"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DayComponent from '@/components/DayComponent';
import utils from '@/mixins/utils';
import AlertComponent from '@/components/AlertComponent';

export default {
  name: 'CampaignComponent',
  components: {DayComponent, AlertComponent},
  props: {
    campaign: {
      type: Object,
      required: false,
      default: null
    },
    affiliates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      updating: false,
      state: {},
      dialog: false,
      newIccid: null,
      targetedFile: null,
      weekDays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      campaigns: [],
      sdks: [],
    }
  },
  mixins: [utils],
  computed: {
    isMno() {
      return this.state.campaignType === 'MNO';
    }
  },
  watch: {
    async isMno(val) {
      if (val) {
        this.campaigns = await this.getCampaigns()
      }
    }
  },
  methods: {
    openDialog() {
      if (this.sdks.length === 0) {
        this.httpGet('api/settings/sdk').then(res => {
          this.sdks = res.data;
        })
      }
      this.dialog = true;
      this.state = this.campaign === null ? {timeFrame: null, emptyMessage: true} : {
        ...this.campaign,
        iphone: this.campaign.platforms.includes('IPHONE'),
        android: this.campaign.platforms.includes('ANDROID'),
        unknownUrl: this.campaign.urls['UNKNOWN'],
        iphoneUrl: this.campaign.urls['IPHONE'],
        androidUrl: this.campaign.urls['ANDROID'],
        emptyMessage: !this.campaign.message || this.campaign.message.length === 0
      };
    },
    async getCampaigns() {
      // TODO: Change endpoint when done in server.
      try {
        return (await this.httpGet('api/campaign/getall')).data
      } catch (e) {
        return [];
      }
    },
    async targetedCheck(campaignId, iccId) {
      const res = await this.httpGet('api/campaign/targeted/check?campaignId=' + campaignId + '&identifier=' + iccId);
      if (res.data) {
        alert('Identifier ' + iccId + ' exists');
      } else {
        alert('Identifier ' + iccId + ' not exists');
      }
    },
    async targetedAction(campaignId, action, identifiers) {
      if (action.includes('_BATCH')) {
        action = action.replace('_BATCH', '');
        if (!this.targetedFile) {
          alert('File must be a csv');
          return;
        }
        let reader = new FileReader();
        reader.readAsText(this.targetedFile, "UTF-8")
        reader.onload = async evt => {
          identifiers = [];
          JSON.parse('["' + evt.target.result.replaceAll('\r', '').replaceAll('\n', '').
          replaceAll(',', '","') + '"]').forEach(iccId => {
            identifiers.push(iccId);
          });
          await this.httpPost('api/campaign/targeted', {campaignId, action, identifiers});
          alert(action === 'UPDATE' ? 'Updated' : 'Removed');
        }
      } else {
        await this.httpPost('api/campaign/targeted', {campaignId, action, identifiers});
        alert(action === 'UPDATE' ? 'Updated' : 'Removed');
      }
    },
    async submit() {
      this.updating = true;
      const update = {
        ...this.state,
      }
      update.platforms = update.iphone ? (update.android ? ['IPHONE', 'ANDROID'] : ['IPHONE']) : (update.android ? ['ANDROID'] : []);
      update.urls = update.iphone ? (update.android ? {IPHONE: update.iphoneUrl, ANDROID: update.androidUrl} :
          {IPHONE: update.iphoneUrl}) : (update.android ? {ANDROID: update.androidUrl} : {UNKNOWN: update.unknownUrl});

      delete update.iphone;
      delete update.android;
      delete update.iphoneUrl;
      delete update.androidUrl;
      delete update.unknownUrl;

      console.log(JSON.stringify(update));

      if (this.campaign === null) {
        switch (update.campaignType) {
          case 'AFFILIATE':
            await this.httpPost('api/campaign/affiliate/create', update);
            break;
          case 'MNO':
            await this.httpPost('api/campaign/mno/create', update);
            break;
          case 'MESSAGE':
            await this.httpPost('api/campaign/direct/create', update);
            break;
        }
        this.$emit('reload');
      } else {
        switch (update.campaignType) {
          case 'AFFILIATE':
            await this.httpPost('api/campaign/affiliate/edit', update);
            break;
          case 'MNO':
            await this.httpPost('api/campaign/mno/edit', update);
            break;
          case 'MESSAGE':
            await this.httpPost('api/campaign/direct/edit', update);
            break;
        }
        Object.assign(this.campaign, update);
      }
      this.updating = false;
      this.dialog = false;
    }
  }
}
</script>
<style scoped>
.card {
  margin: 20px;
  padding: 10px;
}

.box {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 10px;
  padding: 5px;
}


.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
</style>

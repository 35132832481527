<template>
  <div>
    <v-navigation-drawer
        v-if="drawer"
        v-model="drawerState"
        color="#F7F7F7"
        app
    >
      <v-app-bar absolute color="#F7F7F7" app/>
      <div style="margin-top: 80px; padding: 10px;">
        <h3 style="cursor: pointer; margin-bottom: 15px;" @click="go('/')">
          <i class="material-icons" style="vertical-align: middle; font-size: 25px; padding: 5px;">dashboard</i>Overview
        </h3>
        <h3 v-if="doesntHaveRole(['ROLE_REPORT_VIEWER'])" style="cursor: pointer; margin-bottom: 15px;" @click="go('/campaigns')">
          <i class="material-icons" style="vertical-align: middle; font-size: 25px; padding: 5px;">explore</i>Campaigns
        </h3>
<!--        <h3 style="cursor: pointer; margin-bottom: 15px;" @click="go('/affiliates')">-->
<!--          <i class="material-icons"-->
<!--             style="vertical-align: middle; font-size: 25px; padding: 5px;">business</i>Affiliates-->
<!--        </h3>-->
        <h3 style="cursor: pointer; margin-bottom: 15px;" @click="go('/reports')">
          <i class="material-icons" style="vertical-align: middle; font-size: 25px; padding: 5px;">description</i>Reports
        </h3>
        <h3 v-if="hasRole(['ROLE_ADMIN'])" style="cursor: pointer; margin-bottom: 15px;" @click="go('/users')">
          <i class="material-icons"
             style="vertical-align: middle; font-size: 25px; padding: 5px;">supervisor_account</i>Users
        </h3>
        <h3 v-if="hasRole(['ROLE_ADMIN'])" style="cursor: pointer; margin-bottom: 15px;" @click="go('/settings')">
          <i class="material-icons" style="vertical-align: middle; font-size: 25px; padding: 5px;">settings</i>Settings
        </h3>
      </div>
    </v-navigation-drawer>
    <v-app-bar color="#EEEEEE" app>
      <v-app-bar-nav-icon v-if="drawer" @click="drawerState = !drawerState"/>
      <v-toolbar-title style="display: flex; flex-direction: row;">
        <div>
          <img :src="require('@/assets/images/logo.png')" style="width: 140px; height: auto; margin: 9px 0px 0px 0px;">
        </div>
        <h3 v-if="title" style="vertical-align: middle; margin: 6px 15px 0px 0px;">- {{ title }}</h3>
      </v-toolbar-title>
      <slot/>
      <v-spacer></v-spacer>
      <div style="display: flex; flex-direction: column; justify-items: center;">
        <v-btn icon>
          <i class="material-icons" @click="logout">power_settings_new</i>
        </v-btn>
        <span style="font-size: 10px; color: grey;" v-text="'(#' + getGitHash() + ')'"/>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import utils from '@/mixins/utils';

export default {
  name: 'NavComponent',
  mixins: [utils],
  props: {
    drawer: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      drawerState: true,
    }
  },
  methods: {
    go(url) {
      // this.drawerState = false
      this.$router.push(url)
    },
    async logout() {
      await axios.get('api/auth/logout');
      this.$router.replace({path: 'login'});
    }
  }
}
</script>

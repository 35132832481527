<template>
  <div>
    <i class="material-icons materialIcon" @click="openDialog()"
       v-text="user === null ? 'add_circle_outline' : 'create'"/>
    <v-dialog v-model="dialog" width="70%">
      <v-card style="display: flex; flex-direction: column;">
        <v-card-title v-text="user === null ? 'New User' : 'Update User'"/>
        <v-card-text style="overflow-y: scroll;">
          <v-form v-model="formValid">
            <v-text-field :disabled="user !== null" label="User Name" v-model="state.username" />
            <v-select v-if="state.permissions" label="Role" v-model="state.permissions[0]"
                      :items="Object.keys(roles).map(r => {return {text: roles[r], value: r}})"/>
            <v-text-field label="Password" type="password" v-model="state.password" :rules="[validationRules.password]" />
            <v-text-field label="Retype Password" type="password" v-model="state.retypePassword" :rules="[validationRules.passRetype]" />
          </v-form>
        </v-card-text>
        <v-btn class="card" color="secondary" v-text="user === null ? 'ADD' : 'UPDATE'"
               :disabled="!hasRole(['ROLE_ADMIN', 'ROLE_CAMPAIGN_MANAGER'])" @click="submit()"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import utils from '@/mixins/utils';

export default {
  name: 'UserComponent',
  props: {
    user: {
      type: Object,
      required: false,
      default: null
    },
  },
  data() {
    return {
      state: {},
      dialog: false,
      formValid: false,
      validationRules: {
        password: v => (!v || /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v)) || 'Password must be longer than 8 character and must contain at least one number',
        passRetype: value => (value && value === this.state.password) || 'Passwords are not the same',
      }
    }
  },
  mixins: [utils],
  methods: {
    openDialog() {
      this.dialog = true;
      console.log(this.user)
      this.state = this.user === null ? {permissions: ['ROLE_CAMPAIGN_MANAGER']} : {...this.user};
    },
    async submit() {
      if (this.user === null) {
        if (!this.state.username || !this.state.username.toLowerCase().endsWith('ril.com')) {
          alert('User name must end with \'ril.com\'');
          return;
        }
        await this.httpPost('api/user/create', this.state);
        this.$emit('reload');
      } else {
        await this.httpPost('api/user/update', this.state);
        Object.assign(this.user, this.state);
      }
      this.dialog = false;
    }
  }
}
</script>
<style scoped>
.card {
  margin: 20px;
  padding: 10px;
}

.box {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 10px;
  padding: 5px;
}


.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
</style>


<script>
import { Line } from 'vue-chartjs'

export default {
  name: 'LineChartComponent',
  extends: Line,
  props: ['chartdata', 'options', 'height'],
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

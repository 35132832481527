import axios from '../plugins/axios';

export default {
  // middleware: 'authenticated',
  async mounted() {
    this.currentUser = JSON.parse(localStorage.user);
  },
  methods: {
    async httpGet(url, headers) {
      try {
        return await axios.get(url, {headers})
      } catch (e) {
        if (e.response.status === 401 && this.$route.path !== '/login') {
          this.$router.replace({name: 'login'});
          throw e;
        }
      }
    },
    async getCampaigns(force) {
      if (force || !localStorage.campaigns) {
        const campaigns = await this.httpGet('api/campaign/getall');
        localStorage.campaigns = JSON.stringify(campaigns.data);
        return campaigns.data;
      } else {
        return JSON.parse(localStorage.campaigns);
      }
    },
    async httpPost(url, data, headers) {
      try {
        return await axios.post(url, data, {headers})
      } catch (e) {
        if (e.response.status === 401 && this.$route.path !== '/login') {
          this.$router.replace({name: 'login'});
          throw e;
        }
      }
    },
    hasRole(roles) {
      return this.currentUser !== null && this.currentUser.authorities && roles.includes(this.currentUser.authorities[0]);
    },
    doesntHaveRole(roles) {
      return this.currentUser !== null && this.currentUser.authorities && !roles.includes(this.currentUser.authorities[0]);
    },
    hasFeature(feature) {
      return this.currentUser !== null && this.currentUser.features && this.currentUser.features.includes(feature);
    },
    log(message) {
      console.log(message);
    },
    alert(message) {
      alert(message);
    },
    isJsonValid (json) {
      if (json) {
        try {
          JSON.parse(json);
        } catch (e) {
          return false;
        }
      }
      return true;
    },
    getGitHash() {
      return process.env.VUE_APP_GIT_HASH;
    }
  },
  data() {
    return {
      roles: {
        'ROLE_ADMIN': 'Admin',
        'ROLE_CAMPAIGN_MANAGER': 'Campaign Manager',
        'ROLE_MIS_OWNER': 'MIS Owner',
        'ROLE_CAMPAIGN_CREATOR': 'Campaign Creator',
        'ROLE_REPORT_VIEWER': 'Report Viewer',
        'ROLE_TESTER': 'Tester',
      },
      currentUser: null
    }
  },
  head() {
    return {
      title: 'Unibeam'
    }
  }
}


<script>
import { Pie } from 'vue-chartjs'

export default {
  name: 'PieChartComponent',
  extends: Pie,
  props: ['chartdata', 'options', 'height'],
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

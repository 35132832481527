<template>
  <v-app>
    <nav-component :drawer="true" title="Settings"/>
    <v-main>
      <alert-component ref="alert"/>
      <template>
        <div class="cardRow">
          <v-card class="card" style="width: 100%;">
            <v-tabs>
              <v-tab>
                <v-card-title v-text="'System'"/>
              </v-tab>
              <v-tab v-if="hasFeature('extended-settings')">
                <v-card-title v-text="'Applet Params'"/>
              </v-tab>
              <v-tab v-if="hasFeature('extended-settings')">
                <v-card-title v-text="'Debug Control'"/>
              </v-tab>
              <v-tab v-if="hasFeature('extended-settings')">
                <v-card-title v-text="'Applet Kill'"/>
              </v-tab>
              <v-tab v-if="hasFeature('extended-settings')">
                <v-card-title v-text="'Opt Out'"/>
              </v-tab>

              <v-tab-item class="px-6 py-6">
                <v-row>
                  <v-col cols="4">
                    <v-card class="card fill-height">
                      <v-card-title>
                        Black Listed IccIds<span v-if="results.blacklistCount !== undefined"
                                                 v-text="'(' + results.blacklistCount + ')'"/>
                      </v-card-title>
                      <v-card-text>
                        <div class="flexRow">
                          <v-text-field v-model="newIccid" label="Check / Add / Remove Identifier"/>
                          <i class="material-icons materialIcon" style="margin-top: 15px;"
                             v-text="'check_circle_outline'"
                             @click="blacklistCheck(newIccid)"/>
                          <i class="material-icons materialIcon" style="margin-top: 15px;" v-text="'add_circle_outline'"
                             @click="blacklistAction('UPDATE', [newIccid])"/>
                          <i class="material-icons materialIcon" style="margin-top: 15px;"
                             v-text="'remove_circle_outline'"
                             @click="blacklistAction('REMOVE', [newIccid])"/>
                        </div>
                        <div class="flexRow">
                          <v-file-input label="Add / Remove batch (CSV file)" @change="file => blacklistFile = file"/>
                          <i class="material-icons materialIcon" style="margin-top: 15px;" v-text="'add_circle_outline'"
                             @click="blacklistAction('UPDATE_BATCH')"/>
                          <i class="material-icons materialIcon" style="margin-top: 15px;"
                             v-text="'remove_circle_outline'"
                             @click="blacklistAction('REMOVE_BATCH')"/>
                          <i class="material-icons materialIcon" style="margin-top: 15px; margin-left: 15px;"
                             v-text="'delete_outline'"
                             @click="$refs.alert.show('Clear Black List', 'Are you sure you want to clear the black list?',
                                [{text: 'Yes', callback: ()=> {blacklistAction('REMOVE', [])}}, {text: 'No'}])" />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="8">
                    <v-card class="card fill-height">
                      <v-card-title>Allowed Campaigns</v-card-title>
                      <v-card-text>
                        <AllowedCampaigns/>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card>
                      <v-card-title>About</v-card-title>
                      <v-card-text>
                        <p>
                          Client version: #{{getGitHash()}}
                        </p>
                        <p>
                          Server version: #{{serverVersion}}
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="px-6 py-6" v-if="hasFeature('extended-settings')">
                <v-card-text>
                  <div class="flexRow">
                    <v-text-field label="Watch Dog Interval (sec)" type="number"
                                  v-model="results.appletParams.watchDogInterval"/>
                    <div style="width: 20px;"/>
                    <div style="flex-grow: 1;"/>
                  </div>
                  <div class="flexRow">
                    <v-text-field label="Display Text Duration (sec)" type="number"
                                  v-model="results.appletParams.displayTextDuration"/>
                    <div style="width: 20px;"/>
                    <v-text-field label="Poll Interval" type="number" v-model="results.appletParams.pollInterval"/>
                  </div>
                  <div class="flexRow">
                    <v-text-field label="Address" v-model="results.appletParams.address"/>
                    <div style="width: 20px;"/>
                    <v-text-field label="Sms Number" v-model="results.appletParams.serverMsisdnShortCode"/>
                  </div>
                  <div class="flexRow">
                    <v-text-field label="Max Number of Apps" type="number"
                                  v-model="results.appletParams.maxNumberOfApps"/>
                    <div style="width: 20px;"/>
                    <v-text-field label="Shared Secret" type="number" v-model="results.appletParams.sharedSecret"/>
                  </div>
                  <v-textarea label="Opt In Message" v-model="results.appletParams.optInMessage" rows="2"/>
                  <div class="flexRow">
                    <v-text-field label="Opt In Interval" type="number"
                                  v-model="results.appletParams.optInInterval"/>
                    <div style="width: 20px;"/>
                    <v-text-field label="Opt In Hour" type="number" v-model="results.appletParams.optInHour"/>
                  </div>

                </v-card-text>
                <v-card>
                  <v-card-title v-text="'Trigger Events'"/>
                  <v-card-text>
                    <div class="flexRow">
                      <v-text-field label="Handset Reboot" type="number"
                                    v-model="results.appletParams.triggerEvents.handsetReboot"/>
                      <div style="width: 20px;"/>
                      <v-text-field label="Call Disconnect" type="number"
                                    v-model="results.appletParams.triggerEvents.callDisconnect"/>
                    </div>
                    <div class="flexRow">
                      <v-text-field label="SMS Receipt" type="number"
                                    v-model="results.appletParams.triggerEvents.smsReceipt"/>
                      <div style="width: 20px;"/>
                      <v-text-field label="User Activity" type="number"
                                    v-model="results.appletParams.triggerEvents.userActivity"/>
                    </div>
                    <div class="flexRow">
                      <v-text-field label="Idle Screen Available" type="number"
                                    v-model="results.appletParams.triggerEvents.idleScreenAvailable"/>
                      <div style="width: 20px;"/>
                      <div style="flex-grow: 1"/>
                    </div>
                  </v-card-text>
                </v-card>

                <div class="flexRow" style="align-items: center;">
                  <v-btn color="secondary" @click="update" style="margin-top: 20px;" :disabled="results.appletParams.status && results.appletParams.status !== 'ACKNOWLEDGED'">Update</v-btn>
                  <span v-if="results.appletParams.status && results.appletParams.status !== 'ACKNOWLEDGED'" style="margin-top: 20px; margin-left: 10px">Waiting for acknowledge</span>
                </div>
              </v-tab-item>
              <v-tab-item class="px-6 py-6" v-if="hasFeature('extended-settings')">
                <div class="flexRow">
                  <v-text-field label="Identifier" v-model="debugControl.iccId" @input="debugControl.fetched = false"/>
                  <v-btn style="margin-left: 20px;" @click="fetchDebugControl()" v-text="debugControl.fetched ? 'RELOAD' : 'FETCH'" />
                  <div style="flex-grow: 1"/>
                </div>
                <div class="flexRow">
                  <v-switch label="Reset menu" v-model="debugControl.resetMenu"
                            :disabled="!results || !debugControl.fetched" style="margin-right: 15px;"
                            @change="postDebugControl(debugControl.resetMenu ? 'ENABLE_RESET_MENU' : 'DISABLE_RESET_MENU')"/>
                  <v-switch label="Debug log" v-model="debugControl.debugLogging"
                            :disabled="!results || !debugControl.fetched"
                            @change="postDebugControl(debugControl.debugLogging ? 'ENABLE_DEBUG_LOGGING' : 'DISABLE_DEBUG_LOGGING')"/>
                </div>
                <div class="flexRow">
                  <v-btn :disabled="!results" style="margin-top: 25px; margin-right: 15px;"
                         @click="postDebugControl('RETRIEVE_DEBUG_LOGGING')">RETRIEVE DEBUG LOG
                  </v-btn>
                  <v-textarea label="Debug log" v-model="debugControl.debugLog" rows="2" readonly/>
                </div>
                <div class="flexRow">
                  <v-btn :disabled="!results" style="margin-top: 25px; margin-right: 15px;"
                         @click="postDebugControl('RESTART_DEBUG_LOGGING')">RESTART DEBUG LOGGING
                  </v-btn>
                </div>
                <div class="flexRow">
                  <v-btn :disabled="!results" style="margin-top: 25px; margin-right: 15px;"
                         @click="postDebugControl('RETRIEVE_TERMINAL_PROFILE')">RETRIEVE TERMINAL PROFILE
                  </v-btn>
                  <v-textarea label="Terminal profile" v-model="debugControl.terminalProfile" rows="2" readonly/>
                </div>
                <div class="flexRow">
                  <v-btn :disabled="!results" style="margin-top: 25px; margin-right: 15px;"
                         @click="postDebugControl('RETRIEVE_EVENT_COUNTS')">RETRIEVE EVENT COUNTS
                  </v-btn>
                  <v-textarea label="Event counts" v-model="debugControl.eventEvent" rows="2" readonly/>
                </div>

              </v-tab-item>
              <v-tab-item class="px-6 py-6" v-if="hasFeature('extended-settings')">
                <span class="flexRow" style="flex: 1 1 0px; width: 50%;">
                  <v-text-field label="Identifier" v-model="appletKill.iccId"/>
                  <v-btn color="secondary" @click="submitAppletKill()">SUBMIT</v-btn>
                </span>
              </v-tab-item>
              <v-tab-item class="px-6 py-6" v-if="hasFeature('extended-settings')">
                <v-card-text>
                  <v-text-field label="Opt In Message"
                                v-model="results.optOut.optInMessage"/>
                  <v-text-field label="Opt Out Message"
                                v-model="results.optOut.optOutMessage"/>
                  <v-text-field label="Retry Interval" type="number"
                                v-model="results.optOut.retryInterval"/>
                  <v-btn color="secondary" @click="submitOptOutKill()">SUBMIT</v-btn>
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </div>
      </template>
    </v-main>
  </v-app>
</template>

<script>

import utils from '@/mixins/utils';
import NavComponent from '@/components/NavComponent';
import AllowedCampaigns from '../components/AllowedCampaigns';
import AlertComponent from '@/components/AlertComponent';

export default {
  data() {
    return {
      debugControl: {fetched: false, iccId: null},
      appletKill: {},
      results: {appletParams: {triggerEvents: {}}, optOut: {}},
      newIccid: null,
      blacklistFile: null,
      validationRules: {
        numInRange: (v, mn, mx) => (!v || (v >= mn && v <= mx)) || 'Should be in range [' + mn + '..' + mx + ']',
        hex: v => (!v || /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/.test(v)) || 'Should be hex'
      },
      serverVersion: null,
    }
  },
  mounted() {
    this.updateBlacklistCount();
    this.httpGet('api/version').then(res => {
      this.serverVersion = res.data['git.commit.id']
      this.$forceUpdate();
    })
    this.httpGet('api/settings/appletParams').then(res => {
      this.results.appletParams = res.data;
      if (!this.results.appletParams.triggerEvents) {
        this.results.appletParams.triggerEvents = {}
      }
      this.$forceUpdate();
    })
    this.httpGet('api/settings/updateOptSettings').then(res => {
      this.results.optOut = res.data;
      this.$forceUpdate();
    })
  },
  mixins: [utils],
  components: {
    NavComponent,
    AllowedCampaigns,
    AlertComponent
  },
  methods: {
    updateBlacklistCount() {
      this.httpGet('api/settings/blackList/count').then(res => {
        this.results.blacklistCount = res.data
        this.$forceUpdate();
      })
    },
    async update() {
      const appletParams = {...this.results.appletParams};
      if (appletParams.triggerEvents) {
        Object.keys(appletParams.triggerEvents).forEach(k => {
          appletParams.triggerEvents[k] = parseInt(appletParams.triggerEvents[k])
        })
      }
      await this.httpPost('api/settings/appletParams', appletParams);
    },
    async fetchDebugControl() {
      try {
        const res = await this.httpGet('api/settings/appletDebugControl?iccId=' + this.debugControl.iccId);
        this.debugControl.debugLogging = res.data.debugLogging;
        this.debugControl.resetMenu = res.data.resetMenu;
        this.debugControl.terminalProfile = res.data.terminalProfile;
        this.debugControl.debugLog = res.data.debugLog;
        this.debugControl.eventEvent = res.data.eventEvent;
        this.debugControl.fetched = true;
        this.$forceUpdate();
      } catch (e) {
        alert('Identifier not found');
      }
    },
    async postDebugControl(action) {
      this.debugControl.action = action
      await this.httpPost('api/settings/appletDebugControl', this.debugControl);
    },
    async submitAppletKill() {
      await this.httpPost('api/settings/appletKill', this.appletKill);
    },
    async submitOptOutKill() {
      await this.httpPost('api/settings/updateOptSettings', this.results.optOut);
    },
    async blacklistCheck(iccId) {
      const res = await this.httpGet('api/settings/blackList/check?identifier=' + iccId);
      if (res.data) {
        alert('Identifier ' + iccId + ' exists');
      } else {
        alert('Identifier ' + iccId + ' not exists');
      }
    },
    async blacklistAction(action, identifiers) {
      if (action.includes('_BATCH')) {
        action = action.replace('_BATCH', '');
        if (!this.blacklistFile) {
          alert('File must be a csv');
          return;
        }
        let reader = new FileReader();
        reader.readAsText(this.blacklistFile, "UTF-8")
        reader.onload = async evt => {
          identifiers = [];
          JSON.parse('["' + evt.target.result.replaceAll('\r', '').replaceAll('\n', '').
          replaceAll(',', '","') + '"]').forEach(iccId => {
            identifiers.push(iccId);
          });
          await this.httpPost('api/settings/blackList', {action, identifiers});
          alert(action === 'UPDATE' ? 'Updated' : 'Removed');
          this.updateBlacklistCount();
        }
      } else {
        await this.httpPost('api/settings/blackList', {action, identifiers});
        alert(action === 'UPDATE' ? 'Updated' : 'Removed');
        this.updateBlacklistCount();
      }
    },
  },
}
</script>
<style scoped>

.cardRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.card {
  /*width: 50%;*/
  /*margin: 20px;*/
  /*padding: 20px;*/
}

.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
</style>

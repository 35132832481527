<template>
  <v-app>
    <nav-component :drawer="true" title="Overview"/>
    <v-main>
      <template>
        <template v-if="results">
          <v-btn color="secondary" @click="datesDialog = true" style="margin: 30px 0px 0px 30px;">{{ dates[0] }} -
            {{ dates[1] }}
          </v-btn>
          <v-dialog v-model="datesDialog" width="400">
            <v-date-picker color="secondary" v-model="dates" range @change="onChangeDates()"/>
          </v-dialog>
          <div class="cardRow flex-wrap">
            <v-card class="card center">
              <h2 v-text="totalRegisters"/>
              <div>
                TOTAL REGISTERS
              </div>
            </v-card>
            <v-card class="card center">
              <h2 v-text="totalClicks"/>
              <div>
                TOTAL CLICKS
              </div>
            </v-card>
            <v-card class="card center">
              <h2 v-text="totalInstalls"/>
              <div>
                TOTAL INSTALLS
              </div>
            </v-card>
            <v-card class="card center">
              <h2 v-text="totalUninstalls"/>
              <div>
                TOTAL UNINSTALLS
              </div>
            </v-card>
            <v-card class="card center">
              <h2 v-text="totalMessages"/>
              <div>
                TOTAL MESSAGES
              </div>
            </v-card>
            <v-card class="card center">
              <h2 v-text="totalCampaigns"/>
              <div>
                TOTAL CAMPAIGNS
              </div>
            </v-card>
            <v-card class="card center">
              <h2 v-text="totalViews"/>
              <div>
                TOTAL VIEWS
              </div>
            </v-card>
          </div>
          <div class="cardRow">
            <v-card class="card center">
              Click Rate:
              <v-progress-circular :rotate="270" :size="60" :width="5" :value="totalClicks * 100 / totalMessages"
                                   color="#2A809B">
                {{ (totalClicks / totalMessages).toFixed(2) + '%' }}
              </v-progress-circular>
            </v-card>
            <v-card class="card center">
              Install Rate:
              <v-progress-circular :rotate="270" :size="60" :width="5" :value="totalInstalls * 100 / totalClicks"
                                   color="#3D9DFF">
                {{ (totalInstalls / totalClicks).toFixed(2) + '%' }}
              </v-progress-circular>
            </v-card>
            <v-card class="card center">
              Uninstall Rate:
              <v-progress-circular :rotate="270" :size="60" :width="5"
                                   :value="totalInstalls === 0 ? 0 : totalUninstalls * 100 / totalInstalls"
                                   color="#310249">
                {{ (totalInstalls === 0 ? 0 : (totalUninstalls / totalInstalls)).toFixed(2) + '%' }}
              </v-progress-circular>
            </v-card>
            <v-card class="card center">
              View Rate:
              <v-progress-circular :rotate="270" :size="60" :width="5" :value="viewRate * 100"
                                   color="#2A809B">
                {{ (viewRate || viewRate === 0) ? viewRate.toFixed(2) + '%' : 'N/A' }}
              </v-progress-circular>
            </v-card>
          </div>
          <div class="cardRow">
            <v-card class="card">
              <v-card-title>Daily Breakdown</v-card-title>
              <v-card-text>
                <line-chart-component :chartdata="{
                labels: results.activity.map(a => $date(new Date(a.date), 'yyyy-MM-dd')),
                datasets: [{
                  label: 'Clicks',
                  borderColor: '#2A809B',
                  fill: false,
                  data: results.activity.map(a => a.clicks)
                }, {
                  label: 'Installs',
                  borderColor: '#3D9DFF',
                  fill: false,
                  data: results.activity.map(a => a.installs)
                }, {
                  label: 'Registers',
                  borderColor: '#3C5594',
                  fill: false,
                  data: results.activity.map(a => a.registers)
                }, {
                  label: 'Uninstalls',
                  borderColor: '#310249',
                  fill: false,
                  data: results.activity.map(a => a.uninstalls)
                }, {
                  label: 'Messages',
                  borderColor: '#677B80',
                  fill: false,
                  data: results.activity.map(a => a.messages)
                }]
               }" :options="{responsive: true, height: 50}" height="{300}"/>
              </v-card-text>
            </v-card>
          </div>
          <div class="cardRow">
            <v-card class="card" style="width: 50%">
              <v-card-title>
                Device Segmentation ({{ segmentation ? 'OS' : 'Models' }})
              </v-card-title>
              <v-card-text>
                <pie-chart-component :chartdata="segmentationChartData"/>
                <v-btn color="secondary" @click="segmentation = !segmentation">
                  {{ segmentation ? 'Change to Models' : 'Change to OS' }}
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card class="card" style="width: 50%">
              <v-card-title>
                <div class="cardRow">
                  <div>Top Preformed Campaigns</div>
                </div>
              </v-card-title>
              <v-card-text>
                <div v-for="(campaign, i) of results.campaigns" :key="i" style="margin-bottom: 10px;">
                  <div class="flexRow">
                    <h4 v-text="campaign.name"/>
                    <div class="spacer"/>
                    <div v-text="(campaign.clicks / campaign.views).toFixed(2) + '%'"/>
                    <div>
                      ({{ campaign.clicks }} / {{ campaign.views }})
                    </div>
                  </div>
                  <v-progress-linear :value="campaign.clicks * 100 / campaign.views" :height="15" color="#2A809B"
                                     rounded/>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </template>
        <v-overlay :value="!results">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
    </v-main>
  </v-app>
</template>


<script>

  import utils from "@/mixins/utils";
  import LineChartComponent from "@/components/LineChartComponent";
  import PieChartComponent from "@/components/PieChartComponent";
  import NavComponent from "@/components/NavComponent";

  export default {
    async mounted() {
      await this.loadData();
    },
    mixins: [utils],
    data() {
      return {
        dates: [this.$date(this.monthAgo(), 'yyyy-MM-dd'), this.$date(new Date(), 'yyyy-MM-dd')],
        datesDialog: false,
        results: null,
        segmentation: true,
      }
    },
    components: {
      LineChartComponent, PieChartComponent, NavComponent
    },
    computed: {
      totalRegisters() {
        return this.results.activity.map(a => a.registers).reduce((t, n) => (t + n));
      },
      totalClicks() {
        return this.results.activity.map(a => a.clicks).reduce((t, n) => (t + n));
      },
      totalInstalls() {
        return this.results.activity.map(a => a.installs).reduce((t, n) => (t + n));
      },
      totalUninstalls() {
        return this.results.activity.map(a => a.uninstalls).reduce((t, n) => (t + n));
      },
      totalMessages() {
        const sum = this.results.activity.map(a => a.messages).reduce((t, n) => (t + n));
        return sum || sum === 0 ? sum : 'N/A'
      },
      totalCampaigns() {
        return this.results.campaigns.length;
      },
      totalViews() {
        const sum = this.results.activity.map(a => a.views).reduce((t, n) => (t + n));
        return sum || sum === 0 ? sum : 'N/A'
      },
      viewRate() {
        return this.totalViews / this.totalMessages;
      },
      segmentationChartData() {
        const data = this.segmentation ?
                this.results.segmentation.operatingSystems :
                this.results.segmentation.models
        return {
          datasets: [{
            data: data.map(d => d.count),
            backgroundColor: ['#2A809B', '#3D9DFF', '#3C5594', '#310249', '#677B80']
          }],
          labels: data.map(d => d.type),
        }
      }
    },
    methods: {
      monthAgo() {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date;
      },
      async onChangeDates () {
        const date0 = new Date(this.dates[0]);
        const date1 = new Date(this.dates[1]);
        if (date0.getTime() === date1.getTime()) {
          this.date[1] = this.$date(new Date(date0.getTime() + 1000 * 60 * 60 * 24), 'yyyy-MM-dd');
        } else if (date0.getTime() > date1.getTime()) {
          const t = this.dates[0];
          this.dates[0] = this.dates[1];
          this.dates[1] = t;
        }
        await this.loadData();
      },
      async loadData() {
        this.datesDialog = false;
        this.results = null;
        const activity = await this.httpGet('api/overview/activity?startDate=' + this.dates[0] + 'T00:00:00.000Z&endDate=' + this.dates[1] + 'T00:00:00.000Z');
        const segmentation = await this.httpGet('api/overview/segmentation?startDate=' + this.dates[0] + 'T00:00:00.000Z&endDate=' + this.dates[1] + 'T00:00:00.000Z');
        const campaigns = await this.httpGet('api/overview/campaigns');
        this.results = {activity: activity.data, segmentation: segmentation.data, campaigns: campaigns.data};
      },
    }
  }
</script>
<style scoped>
  .cardRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
  }

  .card {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 20px;
    padding: 20px;
  }

  .center {
    text-align: center;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
  }

  .spacer {
    flex-grow: 1;
  }
</style>

<template>
  <v-app>
    <nav-component :drawer="true" title="Campaigns">
      <div class="flexRow" style="margin-top: 20px;">
        <v-text-field label="Search" style="width: 120px; margin-right: 5px;" v-model="search" @input="filter()"/>
        <v-select label="Type" :items="['MNO', 'Message', 'Any']" style="width: 100px; margin-right: 5px;"
                  v-model="type" @change="filter()"/>
        <v-select label="Platform" :items="['Android', 'iOS', 'Any']" style="width: 100px; margin-right: 5px;"
                  v-model="platform" @change="filter()"/>
        <v-select label="Status" :items="['Active', 'Inactive', 'Any']" style="width: 100px; margin-right: 5px;"
                  v-model="status" @change="filter()"/>
        <v-select label="Sort By" :items="['Views', 'Clicks', 'Conv. Rate', 'Last Active']"
                  style="width: 120px; margin-right: 5px;" v-model="sortBy" @change="filter()"/>
        <campaign-component v-if="results && hasRole(['ROLE_ADMIN', 'ROLE_CAMPAIGN_MANAGER', 'ROLE_CAMPAIGN_CREATOR'])" :affiliates="results.affiliates" @reload="loadData()"/>
      </div>
    </nav-component>
    <v-main>
      <alert-component ref="deleteAlert"/>
      <template v-if="results && results.campaigns">
        <v-card v-for="(campaign, i) of campaigns" :key="i" class="card">
          <div class="flexRow">
            <v-card-title v-text="campaign.name"></v-card-title>
            <div class="spacer" />
            <v-card-actions>
              <i class="material-icons materialIcon">access_time</i>
              <timeago :datetime="campaign.updatedAt"/>
              <campaign-component v-if="hasRole(['ROLE_ADMIN', 'ROLE_CAMPAIGN_MANAGER', 'ROLE_CAMPAIGN_CREATOR'])" :campaign="campaign" :affiliates="results.affiliates"/>
              <a v-if="campaign.previewUrl" :href="campaign.previewUrl" target="_blank">
                <i class="material-icons materialIcon">open_in_new</i>
              </a>
              <v-switch v-model="campaign.active" color="secondary" :disabled="!hasRole(['ROLE_ADMIN', 'ROLE_CAMPAIGN_MANAGER'])" @change="setActive(campaign.id, campaign.active)"/>
              <i class="material-icons materialIcon" v-if="hasRole(['ROLE_ADMIN', 'ROLE_CAMPAIGN_MANAGER'])"
                 @click="$refs.deleteAlert.show('Delete Campaign', 'Are you sure you want to delete this campaign?',
                [{text: 'Yes', callback: ()=> {deleteCampaign(campaign.id)}}, {text: 'No'}])">delete</i>
            </v-card-actions>
          </div>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="flexRow" style="justify-content: space-evenly;">
                  <div>
                    TYPE
                    <h3 v-text="campaign.campaignType"/>
                  </div>
                  <div>
                    PAYOUT
                    <h3 v-text="'$' + campaign.payout.toFixed(2)"/>
                  </div>
                  <div>
                    VIEWS
                    <h3 v-text="campaign.views"/>
                  </div>
                  <div>
                    CLICKS
                    <h3 v-text="campaign.clicks"/>
                  </div>
                  <div>
                    CONVERSION RATE
                    <h3 v-text="(campaign.clicks / campaign.views).toFixed(2) + '%'"/>
                  </div>
                  <div>
                    PLATFORMS
                    <h3>
                      <img v-if="campaign.platforms.includes('IPHONE')" :src="require('@/assets/images/ios.png')"
                           style="width: 20px; height: auto;"/>
                      <img v-if="campaign.platforms.includes('ANDROID')" :src="require('@/assets/images/android.png')"
                           style="width: 20px; height: auto;"/>
                    </h3>
                  </div>
                  <div>
                    USER LIMIT
                    <h3 v-text="campaign.limit < 0 ? 'Unlimited' : campaign.limit"/>
                  </div>
                  <div>
                    TRIGGER TYPE
                    <h3 v-text="campaign.triggerType || 'N/A'"/>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panel v-if="campaign.campaignType === 'MNO'">
                      <v-expansion-panel-header>
                        Message
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="flexRow">
                          <v-card class="box">
                            Register Text
                            <h3 v-text="campaign.registerText"/>
                          </v-card>
                          <v-card class="box">
                            Message Type
                            <h3 v-text="campaign.messageType"/>
                          </v-card>
                          <v-card class="box">
                            Retry Text
                            <h3 v-text="campaign.retryText"/>
                          </v-card>
                        </div>
                        <div class="flexRow">
                          <v-card class="box">
                            Retry Text (After Link Open)
                            <h3 v-text="campaign.retryText2"/>
                          </v-card>
                          <v-card class="box">
                            Max. Retries
                            <h3 v-text="campaign.maxRetries"/>
                          </v-card>
                          <v-card class="box">
                            Retry Interval
                            <h3 v-text="campaign.retryInterval + ' mins'"/>
                          </v-card>
                        </div>
                        <div class="flexRow">
                          <v-card class="box">
                            Unpair Text
                            <h3 v-text="campaign.unpairText"/>
                          </v-card>
                          <v-card class="box">
                            Max. Unpairs
                            <h3 v-text="campaign.maxUnpairs"/>
                          </v-card>
                          <v-card class="box">
                            Unpair Interval
                            <h3 v-text="campaign.unpairInterval + ' mins'"/>
                          </v-card>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-card class="box" v-else-if="campaign.campaignType === 'MESSAGE'">
                      Message
                      <h3 v-text="campaign.message"/>
                    </v-card>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Schedule
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="flexRow">
                          <v-card class="box">
                            Monday
                            <h3 v-text="parseTimeframe(campaign.timeFrame.mon)"/>
                          </v-card>
                          <v-card class="box">
                            Tuesday
                            <h3 v-text="parseTimeframe(campaign.timeFrame.tue)"/>
                          </v-card>
                          <v-card class="box">
                            Wednesday
                            <h3 v-text="parseTimeframe(campaign.timeFrame.wed)"/>
                          </v-card>
                          <v-card class="box">
                            Thursday
                            <h3 v-text="parseTimeframe(campaign.timeFrame.thu)"/>
                          </v-card>
                          <v-card class="box">
                            Friday
                            <h3 v-text="parseTimeframe(campaign.timeFrame.fri)"/>
                          </v-card>
                          <v-card class="box">
                            Saturday
                            <h3 v-text="parseTimeframe(campaign.timeFrame.sat)"/>
                          </v-card>
                          <v-card class="box">
                            Sunday
                            <h3 v-text="parseTimeframe(campaign.timeFrame.sun)"/>
                          </v-card>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </template>
      <v-overlay :value="!results">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>

import utils from '@/mixins/utils';
import CampaignComponent from '@/components/CampaignComponent';
import NavComponent from '@/components/NavComponent';
import AlertComponent from '@/components/AlertComponent';

export default {
  async mounted() {
    await this.loadData();
  },
  mixins: [utils],
  methods: {
    async loadData() {
      this.results = null;
      const campaigns = await this.getCampaigns(true);
      const offers = await this.httpGet('api/offer');
      this.results = {
        campaigns, affiliates: offers.data.map(o => {
          return {text: o.campaignName, value: o.id}
        })
      };
      this.filter();
    },
    parseTimeframe(timeframe) {
      try {
        if (timeframe.from === 0 && timeframe.to === 24) {
          return 'All Day'
        } else {
          return timeframe.from + ' - ' + timeframe.to
        }
      } catch(e) {
        return 'Disabled'
      }
    },
    filter() {
      this.campaigns = this.results.campaigns;
      if (this.search !== null && this.search.length > 0) {
        this.campaigns = this.campaigns.filter((c) => {
          return c.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }
      switch (this.type) {
        case 'MNO':
          this.campaigns = this.campaigns.filter((c) => {
            return c.campaignType === 'MNO'
          })
          break;
        case 'Message':
          this.campaigns = this.campaigns.filter((c) => {
            return c.campaignType === 'MESSAGE'
          })
          break;
      }
      switch (this.platform) {
        case 'Android':
          this.campaigns = this.campaigns.filter((c) => {
            return c.platforms.includes('ANDROID')
          })
          break;
        case 'iOS':
          this.campaigns = this.campaigns.filter((c) => {
            return c.platforms.includes('IPHONE')
          })
          break;
      }
      switch (this.status) {
        case 'Active':
          this.campaigns = this.campaigns.filter((c) => {
            return c.active === true
          })
          break;
        case 'Inactive':
          this.campaigns = this.campaigns.filter((c) => {
            return c.active === false
          })
          break;
      }
      switch (this.sortBy) {
        case 'Views':
          this.campaigns = this.campaigns.sort((c0, c1) => {
            return c1.views - c0.views
          })
          break;
        case 'Clicks':
          this.campaigns = this.campaigns.sort((c0, c1) => {
            return c1.clicks - c0.clicks
          })
          break;
        case 'Conv. Rate':
          this.campaigns = this.campaigns.sort((c0, c1) => {
            return (c1.clicks / c1.views) - (c0.clicks / c0.views)
          })
          break;
        case 'Last Active':
          this.campaigns = this.campaigns.sort((c0, c1) => {
            return new Date(c1.updatedAt).getTime() - new Date(c0.updatedAt).getTime()
          })
          break;
      }
    },
    async deleteCampaign(id) {
      await this.httpPost('api/campaign/delete', {id});
    },
    async setActive(id, active) {
      await this.httpPost('api/campaign/setActive', {id, active});
    }
  },
  data() {
    return {
      search: null,
      type: 'Any',
      platform: 'Any',
      status: 'Any',
      sortBy: 'Views',
      results: null,
      campaigns: null,
    }
  },
  components: {AlertComponent, CampaignComponent, NavComponent}
}
</script>

<style scoped>

.card {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 20px;
  padding: 10px;
}

.box {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 10px;
  padding: 5px;
}

.spacer {
  flex-grow: 1;
}

.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
</style>

<template>
  <v-app>
    <nav-component :drawer="true" title="Affiliates">
      <div class="flexRow" style="margin-top: 20px;">
        <v-text-field label="Search" style="width: 120px; margin-right: 5px;" @input="filter()"/>
        <v-select label="Platform" :items="['Android', 'iOS', 'Any']" style="width: 100px; margin-right: 5px;"
                  v-model="platform" @change="filter()"/>
        <v-select label="Sort By" :items="['Capacity', 'Expires', 'Payout']"
                  style="width: 120px; margin-right: 5px;" v-model="sortBy" @change="filter()"/>
      </div>
    </nav-component>
    <v-main>
      <template v-if="results && results.offers">
        <v-card v-for="(offer, i) of offers" :key="i" class="card">
          <div class="flexRow">
            <v-card-title v-text="offer.campaignName"></v-card-title>
            <div class="spacer"/>
            <v-card-actions>
              <a v-if="offer.previewUrl" :href="offer.previewUrl" target="_blank">
                <i class="material-icons materialIcon">open_in_new</i>
              </a>
            </v-card-actions>
          </div>
          <v-card-text>
            <div class="flexRow" style="justify-content: space-evenly;">
              <div>
                AFFILIATE
                <h3 v-text="offer.affiliateName"/>
              </div>
              <div>
                PAYOUT
                <h3 v-text="'$' + offer.payout.toFixed(2)"/>
              </div>
              <div>
                CAPACITY
                <h3 v-text="offer.dailyCap !== null ? offer.dailyCap : 'Unlimited' "/>
              </div>
              <div>
                PLATFORMS
                <h3>
                  <img v-if="offer.platforms.includes('IPHONE')" :src="require('@/assets/images/ios.png')"
                       style="width: 20px; height: auto;"/>
                  <img v-if="offer.platforms.includes('ANDROID')" :src="require('@/assets/images/android.png')"
                       style="width: 20px; height: auto;"/>
                </h3>
              </div>
              <div>
                EXPIRES
                <h3 v-text="offer.expiryDate !== null ? offer.expiryDate : 'Expired' "/>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <v-overlay :value="!results">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>

import utils from "@/mixins/utils";
import NavComponent from '@/components/NavComponent';

export default {
  components: {NavComponent},
  async mounted() {
    await this.loadData();
  },
  mixins: [utils],
  methods: {
    async loadData() {
      const offer = await this.httpGet('api/offer');
      this.results = {offers: offer.data};
      this.filter();
    },
    filter() {
      this.offers = this.results.offers;
      if (this.search !== null && this.search.length > 0) {
        this.offers = this.offers.filter((o) => {
          return o.campaignName.toLowerCase().includes(this.search.toLowerCase()) ||
              o.affiliateName.toLowerCase().includes(this.search.toLowerCase())
        })
      }
      switch (this.platform) {
        case 'Android':
          this.offers = this.offers.filter((o) => {
            return o.platforms.includes('ANDROID')
          })
          break;
        case 'iOS':
          this.offers = this.offers.filter((o) => {
            return o.platforms.includes('IPHONE')
          })
          break;
      }
      switch (this.sortBy) {
        case 'Capacity':
          this.offers = this.offers.sort((o0, o1) => {
            return (o1.dailyCap === null ? 0 : o1.dailyCap) - (o0.dailyCap === null ? 0 : o0.dailyCap)
          })
          break;
        case 'Expires':
          this.offers = this.offers.sort((o0, o1) => {
            return (o1 ? new Date(o1.expiryDate).getTime() : 0) - (o0 ? new Date(o0.expiryDate).getTime() : 0)
          })
          break;
        case 'Payout':
          this.offers = this.offers.sort((o0, o1) => {
            return o1.payout - o0.payout
          })
          break;
      }
    }
  },
  data() {
    return {
      search: null,
      platform: 'Any',
      sortBy: 'Capacity',
      results: null,
      offers: null,
    }
  },
}
</script>

<style scoped>
.card {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 20px;
  padding: 10px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}
</style>

<template>
  <div>
    <v-checkbox v-model="checked" @change="checkChange()"/>
    <span style="margin: auto 15px auto 0px; width: 75px;" v-text="weekDaysMap[day]"/>
    <v-text-field style="margin-right: 15px;" label="From" type="number" v-model="from"
                  :disabled="!checked" @input="timeframes[day].from = parseInt(from)"/>
    <v-text-field style="margin-right: 15px;" label="To" type="number" v-model="to" :disabled="!checked"
                  @input="timeframes[day].to = parseInt(to)"/>
  </div>
</template>

<script>

export default {
  name: 'DayComponent',
  props: {
    day: {
      type: String,
      required: true
    },
    timeframes: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      checked: this.timeframes ? this.timeframes[this.day] : true,
      from: this.timeframes ? (this.timeframes[this.day] ? this.timeframes[this.day].from : null) : 0,
      to: this.timeframes ? (this.timeframes[this.day] ? this.timeframes[this.day].to : null) : 24,
      weekDaysMap: {
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
        sun: 'Sunday',
      }
    }
  },
  methods: {
    checkChange() {
      if (this.checked) {
        this.from = 0
        this.to = 24
        this.timeframes[this.day] = {from: 0, to: 24}
      } else {
        this.from = null
        this.to = null
        delete this.timeframes[this.day]
      }
    }
  }
}
</script>
<style scoped>

</style>

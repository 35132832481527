<template>
  <div>
    <v-icon class="mr-2" @click="isCampaignModalOpen = true; selectedCampaigns.splice(0, selectedCampaigns.length)">
      mdi-plus
    </v-icon>
    <v-icon class="mr-2" @click="fetchAllowedCampaigns()">mdi-refresh</v-icon>
    <v-icon v-if="selectedCampaigns.length" class="mr-2" @click="isDeleteConfirmationModalOpen = true">mdi-delete
    </v-icon>
    <v-icon v-if="selectedCampaigns.length" class="mr-2" @click="isCampaignModalOpen = true;">mdi-pencil</v-icon>
    <v-data-table
        v-model="selectedCampaigns"
        :loading="isFetchAllowedCampaignsInProgress"
        :headers="tableHeaders"
        :items="campaigns"
        :items-per-page="5"
        single-select
        show-select
        item-key="'' + id">
      <template v-slot:item.url="{ item }">
        <div class="d-flex align-center">
          <v-img :src="require('@/assets/images/android.png')" max-height="1em" max-width="1em" class="mr-2"/>
          {{ item.urls['ANDROID'] }}
        </div>
        <div class="d-flex align-center">
          <v-img :src="require('@/assets/images/ios.png')" max-height="1em" max-width="1em" class="mr-2"/>
          {{ item.urls['IPHONE'] }}
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="isCampaignModalOpen" max-width="600">
      <v-form @submit="createOrUpdateCampaign(); isCampaignModalOpen = false">
        <v-card>
          <v-card-title>{{ selectedCampaigns.length ? 'Edit' : 'Create' }}</v-card-title>
          <v-card-text>

            <v-text-field v-model="formCampaign.name" label="Name"/>
            <v-text-field v-model="formCampaign.appId" label="App ID"/>
            <v-text-field
                v-if="formCampaign.urls"
                v-model="formCampaign.urls['IPHONE']"
                label="iPhone URL"/>
            <v-text-field
                v-if="formCampaign.urls"
                v-model="formCampaign.urls['ANDROID']"
                label="Android URL"/>
            <v-textarea label="Token" rows="2" v-model="formCampaign.token"
                        :rules="[validationRules.json]"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" type="submit">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

    </v-dialog>
    <v-dialog v-model="isDeleteConfirmationModalOpen" max-width="400">
      <v-card v-if="selectedCampaigns.length">
        <v-card-title>Remove Campaign</v-card-title>
        <v-card-text>Are you sure you want to remove campaign {{ selectedCampaigns[0].name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isDeleteConfirmationModalOpen = false;">Cancel</v-btn>
          <v-btn @click="deleteCampaign(selectedCampaigns[0].id); isDeleteConfirmationModalOpen = false;">Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import utils from '@/mixins/utils';

export default {
  name: 'AllowedCampaigns',
  data() {
    return {
      state: {},
      campaigns: [],
      selectedCampaigns: [],
      tableHeaders: [
        {text: 'Name', value: 'name'},
        {text: 'App ID', value: 'appId'},
        {text: 'URL', value: 'url'},
      ],
      isCampaignModalOpen: false,
      isFetchAllowedCampaignsInProgress: false,
      isDeleteConfirmationModalOpen: false,
      validationRules: {
        // eslint-disable-next-line no-useless-escape
        json: v => this.isJsonValid(v) || 'Please enter a valid JSON',
      }
    }
  },
  mixins: [utils],
  methods: {
    async fetchAllowedCampaigns() {
      this.isFetchAllowedCampaignsInProgress = true;
      this.campaigns = (await this.httpGet('api/settings/sdk')).data
      this.isFetchAllowedCampaignsInProgress = false;
    },
    async createAllowedCampaign(campaign) {
      // TODO:
      return campaign;
    },
    async editAllowedCampaign(campaign) {
      // TODO:
      return campaign;
    },
    async deleteCampaign(campaignId) {
      // TODO:
      await this.fetchAllowedCampaigns()
      this.campaigns.splice(this.campaigns.findIndex(c => c.id === campaignId), 1) // HardCoded
      return campaignId;
    },
    async createOrUpdateCampaign() {
      if (this.selectedCampaigns.length) {
        const campaign = this.campaigns.find(c => c.id === this.selectedCampaigns[0].id) // HARD CODED
        Object.assign(campaign, this.formCampaign)
        campaign.token = JSON.parse(campaign.token)
        await this.httpPost('api/settings/sdk', campaign);
      } else {
        const campaign = {...this.formCampaign};
        campaign.token = JSON.parse(campaign.token)
        await this.httpPost('api/settings/sdk', campaign);
      }
      await this.fetchAllowedCampaigns()
    },
  },
  computed: {
    formCampaign() {
      if (this.selectedCampaigns.length) {
        // eslint-disable-next-line no-unused-vars
        const {id, ...campaign} = this.selectedCampaigns[0];
        const ret = Object.assign({}, campaign)
        ret.token = ret.token ? JSON.stringify(ret.token) : null
        return ret;
      }
      return {name: '', appId: '', urls: {}}
    },
  },
  mounted() {
    this.fetchAllowedCampaigns()
  }
}
</script>
<style scoped>
.card {
  margin: 20px;
  padding: 10px;
}

.box {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 10px;
  padding: 5px;
}


.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.v-textarea textarea {
  font-family: monospace !important;
}
</style>
